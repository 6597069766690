import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/(layoutGeneral)/retry.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/atoms/AdContainer/BoxAds.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/atoms/ButtonImage/ButtonImage.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/atoms/ClientImage/ClientImage.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/atoms/ImageLink/ImageLink.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/atoms/ImageLink/ImageLinkUniversal.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/atoms/ImageLink/ImageLinkVerticalCustom.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/atoms/LinkLoadMore/LinkLoadMore.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/atoms/LinkSimple/LinkSimple.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/molecules/Breadcrumb/Breadcrumb.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/molecules/ButtonShareSocial/ButtonShareSocial.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/molecules/ButtonTabs/ButtonTabs.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/molecules/CardDynamic/TimeLineDynamic.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/molecules/cards/CardYoutube/CardYoutube.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/molecules/EpisodesList/EpisodesList.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/molecules/NewsletterForm/NewsletterForm.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/molecules/NewsletterPolititcs/NewsletterPolitics.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/molecules/PlayerStreaming/PlayerStreaming.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/molecules/ShowsList/ShowsList.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/molecules/Slider/Slider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/molecules/SlideShow/SlideShow.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/molecules/StoryPage/StoryPage.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/molecules/TabSelector/TabSelector.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/molecules/TapToPlayCover/TapToPlayCover.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/molecules/TapToPlayCoverBig/TapToPlayCoverBig.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/molecules/VideoVertical/VideoVertical.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/organisms/BlockCarouselNews/CarouselList.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/organisms/BlockDetailVideo/BlockDetailVideo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/organisms/BlockLiveBlog/BlockLiveBlog.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/organisms/BlockPressRoom/PressRoomHomeCards.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/organisms/BlockPressRoom/PressRoomHomePrincipal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ToggleMap"] */ "/app/src/app/components/organisms/BlockToggleMap/ToggleMap.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/organisms/BloqueHero/HeroSpecial.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/organisms/BloqueHeroGrid/HeroGridCards.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/organisms/BloqueHeroGrid/InteractiveLink.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/organisms/BloqueIndependiente/IndependentFullSize.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/organisms/BloqueMapboxMap/MapboxMap.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/organisms/BloqueMinutoXMinuto/BloqueMinutoXMinutoProvider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/organisms/BloqueProgramas/NavProgramas.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/organisms/BloqueTextoHtml/ParseHTMLJS.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/components/organisms/Grid/GridProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/organisms/PathController/PathController.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/hooks/analytics/SendDataLayer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/lib/PageViewEvent.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/Grid/Grid.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/Molecules/WidgetTitle.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/Molecules/WidgetTitleMedia.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/Molecules/SquareImage.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/Molecules/RectImage.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/BloqueUniversal/BloqueUniversal.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/BloqueMxM/BloqueMxM.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/Blocks/BloqueVideoVertical.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/mapbox/StoryTellingLatin.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/Blocks/BlockCarouselNews.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/Blocks/BloqueMosaico.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/Blocks/BloqueIndependiente.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/Blocks/BloqueTopBanner.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/Blocks/BloqueBotoneraHorizontal.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/Blocks/BloqueWebStories.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/Atoms/VideoDuration.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/Cards/CardVertical.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/Cards/CardTapToPlay.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/Blocks/BlockTopFive.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/SocialShareButtons/SocialShareButtons.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/Blocks/BloqueNewsletter.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/Cards/CardVideoNews.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/Molecules/CarouselVOD.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/Blocks/BloqueVod.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/Blocks/BloqueAperturaLive.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/Blocks/BlockDetailProgram.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/Blocks/BloqueListadoProgramas.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/Blocks/BloqueTextoHtml.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/Molecules/CardTalent.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/Blocks/BloqueTalentsTabs.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/Blocks/BloqueProgramas.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/Molecules/PlayerIcon.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/Blocks/BloqueHero.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/Blocks/BloqueAperturaInf.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/Blocks/BloqueHeroGrid.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/Blocks/BlockTitleTimeline.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/Blocks/BlockPressRoomHome.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/Molecules/BreadCrumb.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/Blocks/BloqueDetailTalent.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/SectionTitle/SectionTitle.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/Blocks/BlockSlideShow.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/app/styles/global.scss");
