"use client";
import { Children, useRef, CSSProperties } from "react";
import dynamic from "next/dynamic";
import Image from "next/image";
import { usePosition } from "@/app/hooks/usePosition";
import styles from "@/app/styles/Molecules/CarouselVOD.module.scss";
const InteractiveLink = dynamic(
	() => import("@/app/components/organisms/BloqueHeroGrid/InteractiveLink")
);
const ButtonSlider = dynamic(
	() => import("@/app/components/atoms/ButtonSlider/ButtonSlider")
);

const CarouselList = ({ blockData, isDark, data }: any) => {
	const elRef = useRef(null);
	const { hasItemL, hasItemR, scrollLeft, scrollRight } = usePosition(elRef);
	if (!Array.isArray(data)) return <></>;
	return (
		<>
			<div className={`${styles["carouselList__container"]}`}>
				<div
					className={`${styles?.carouselList} ${styles["carouselList__scrollableContainer"]}`}
					ref={elRef}
				>
					{Children.toArray(
						data.map((news: any, i: number) => {
							return (
								<figure
									key={news?.id}
									className={styles?.carouselList__showCard}
								>
									<div className={styles?.carouselList__showImgWrapper}>
										<InteractiveLink
											url={news?.path}
											title={news?.title}
											cardPosition={i + 1}
											blockData={blockData}
											isLink={false}
										>
											<Image
												src={
													news?.thumbnail?.imageUrl?.webp ||
													"/assets/placeholder_.svg"
												}
												alt={news?.thumbnail?.alt}
												height={80}
												width={150}
												loading="lazy"
												placeholder="blur"
												blurDataURL={`${
													process.env.NEXT_PUBLIC_BLURIMAGE ||
													"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAADElEQVR4nGP4b+YAAAOtAXam2gnIAAAAAElFTkSuQmCC"
												}`}
											/>
										</InteractiveLink>
									</div>
									<figcaption
										className={styles?.carouselList__showCaption}
										style={
											{
												"--textColor": `${isDark ? "#fff" : "#001621"}`,
											} as CSSProperties
										}
									>
										<span
											className={`${styles["carouselList__tagName"]}`}
											style={
												{
													"--textColor": `${isDark ? "#BFD1DA" : "#2D5D75"}`,
												} as CSSProperties
											}
										>
											{news?.term[0]?.name || ""}
										</span>
										<InteractiveLink
											url={news?.path}
											title={news?.title}
											blockData={blockData}
											cardPosition={i}
										>
											<span
												className={`${styles["carouselList__headLine"]}`}
												style={
													{
														"--textColor": `${isDark ? "#EDF4F8" : "#103D53"}`,
													} as CSSProperties
												}
											>
												{news?.title}
											</span>
										</InteractiveLink>
									</figcaption>
								</figure>
							);
						})
					)}
				</div>
				{hasItemL && <ButtonSlider icon="arrowLeft" onClick={scrollLeft} />}
				{hasItemR && <ButtonSlider icon="arrowRight" onClick={scrollRight} />}
			</div>
		</>
	);
};

export default CarouselList;
