"use client";
import dynamic from "next/dynamic";
import { useResponsive } from "@/app/hooks/useResponsive";
import { usePathname } from "next/navigation";
import style from "@/app/styles/Atoms/Advertising.module.scss";
import indexAds from "@/app/components/atoms/AdContainer/AdsConfig";
const Advertising = dynamic(
	() => import("@/app/components/atoms/AdContainer/Advertising")
);

export default function BoxAds({
	withAd,
	position = 1,
	isDark,
	isGray,
	isLight,
	isAside = false,
	isLastBoxAds = false,
	typeAd = "themes",
}: any) {
	const themesDesktopCombo =
		position >= 5 ? indexAds.desktop.comboMaster : indexAds.desktop.comboSuper;
	const themesMobileCombo = isLastBoxAds
		? indexAds.mobile.comboMobile
		: position >= 4
		? indexAds.mobile.combo
		: indexAds.mobile.combo3;
	const { isDesktop, isTablet } = useResponsive();
	const pathName = usePathname() || "";
	// Se definen los combos según el tipo de ad a mostrar
	const aside = isDesktop ? indexAds.desktop.combo2 : indexAds.mobile.combo;
	const storypage = isDesktop
		? indexAds?.desktop?.combo
		: indexAds?.mobile?.combo;
	const themes = isDesktop ? themesDesktopCombo : themesMobileCombo;
	const banner =
		isDesktop || isTablet
			? indexAds.desktop.comboBanner
			: indexAds.mobile.banner;

	return (
		<div
			className={`
				${style["advertising__boxAd"]}
				${withAd ? style["advertising__boxAd--withAd"] : ""}
				${isDark ? style["advertising__boxAd--isDark"] : ""}
				${isGray ? style["advertising__boxAd--isGray"] : ""}
				${isLight ? style["advertising__boxAd--isLight"] : ""}
				${typeAd === "banner" ? style["advertising__boxAd--banner"] : ""}
			`}
		>
			<Advertising
				typeAd={
					typeAd === "banner"
						? banner
						: typeAd === "storypage"
						? storypage
						: typeAd === "aside"
						? aside
						: themes
				}
				contentType={typeAd}
				urlPath={pathName}
				position={position}
				isAside={isAside}
			/>
		</div>
	);
}
