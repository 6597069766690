import dynamic from "next/dynamic";
import MultiVideoSlider from "../StoryPageMedia/Videos/MultiVideoSlider/MultiVideoSlider";
import style from "@/app/styles/Molecules/MediaStoryPage.module.scss";
const Icon = dynamic(() => import("@/app/components/atoms/Icon/Icon"));
const SingleImage = dynamic(
	() =>
		import(
			"@/app/components/molecules/StoryPageMedia/Images/SingleImage/SingleImage"
		)
);
const MultiImage = dynamic(
	() =>
		import(
			"@/app/components/molecules/StoryPageMedia/Images/MultiImage/MultiImage"
		)
);
const SingleVideo = dynamic(
	() =>
		import(
			"@/app/components/molecules/StoryPageMedia/Videos/SingleVideo/SingleVideo"
		)
);
const MultiVideo = dynamic(
	() =>
		import(
			"@/app/components/molecules/StoryPageMedia/Videos/MultiVideo/MultiVideo"
		)
);

type Props = {
	cardItem?: any;
	aspect?: string;
	height?: string;
	isOpening?: boolean;
	noLowerDescription?: boolean;
	isVariantBulletin?: boolean;
	dataLayer?: any;
	showPagination?: boolean;
	showNavigation?: boolean;
	showZoomOption?: boolean;
	isLiveBlog?: boolean;
	isVertical?: boolean;
	isAlternativeVideos?: boolean;
	isPinned?: boolean;
	carouselMedia?: string;
};

export default function MediaStoryPage({
	aspect = "16/9",
	height,
	cardItem,
	isOpening,
	noLowerDescription = false,
	isVariantBulletin = false,
	dataLayer,
	isLiveBlog = false,
	showPagination = false,
	showNavigation = true,
	showZoomOption = false,
	isVertical = false,
	isAlternativeVideos = false,
	isPinned,
	carouselMedia,
}: Props) {
	const { videoStory, relatedVideos, videos, file, title, images } =
		cardItem ?? {};
	const type = (() => {
		if (
			(videoStory && relatedVideos?.length === 0) ||
			(videos?.length === 1 && videos?.[0]?.cmsid !== null)
		) {
			return "singlevideo";
		}
		if (videoStory && relatedVideos?.length > 0) {
			return "multivideo";
		}
		if (images?.length === 0 || isVariantBulletin) {
			return "singleimage";
		}
		if (images?.filter((item: any) => item.image !== null)?.length >= 2) {
			return "multiimage";
		}
		return "fallback";
	})();
	let mergedData;
	if (type === "multivideo") {
		mergedData = {
			multiVideos: [videoStory, ...relatedVideos],
		};
	}
	// TODO Single Image click Live Blog Data Layer
	return (
		<>
			<div
				className={`
				${style["media__mediaWrapper"]}
				${
					isLiveBlog && !isPinned
						? `${style["liveblog"]}`
						: isLiveBlog && isPinned
						? `${style["media__mediaWrapper__liveblog--pinned"]}`
						: ""
				}
				${
					type === "multiimage" && isLiveBlog
						? style["media__mediaWrapper--carousel"]
						: ""
				}
				${
					carouselMedia && carouselMedia === "multiVideo"
						? style["media__mediaWrapper--multiVideo"]
						: carouselMedia && carouselMedia === "multiImage"
						? style["media__mediaWrapper--multiImage"]
						: ""
				}
				`}
			>
				{(type === "singleimage" || type === "fallback") && (
					<>
						<SingleImage
							cardItem={cardItem}
							aspect={aspect}
							height={height}
							isVariantBulletin={isVariantBulletin}
							isliveblog={isLiveBlog}
							isPinned={isPinned}
						/>
						{isVariantBulletin && file?.uri?.url && (
							<a
								rel="noreferrer"
								className={`${style["media__wrapperDownloadButton"]}`}
								target="_blank"
								href={file?.uri?.url}
								download={file?.filename || "N+ Sala de prensa"}
							>
								<Icon name="file" />
								Descargar
							</a>
						)}
					</>
				)}
				{type === "multiimage" && (
					<MultiImage
						isOpening={isOpening}
						cardItem={cardItem}
						typeMedia={type}
						aspectRatio={aspect}
						showNavigation={showNavigation}
						showPagination={showPagination}
						showZoomOption={showZoomOption}
						isLiveBlog={isLiveBlog}
					/>
				)}
				{type === "singlevideo" && (
					<SingleVideo
						cardItem={videoStory || videos?.[0]}
						alterTitle={title}
						noLowerDescription={noLowerDescription}
						dataLayer={dataLayer}
						isVertical={isVertical}
						isLiveBlog={isLiveBlog}
					/>
				)}
				{type === "multivideo" && !isAlternativeVideos && (
					<MultiVideo
						isOpening={isOpening}
						cardItem={mergedData?.multiVideos}
					/>
				)}
				{type === "multivideo" && isAlternativeVideos && (
					<MultiVideoSlider
						cardItem={mergedData?.multiVideos}
						typeMedia={type}
						isLiveBlog={isLiveBlog}
					/>
				)}
			</div>
		</>
	);
}
